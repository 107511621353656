import { useState, memo, useMemo } from "react"
import { fromJS } from "immutable"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import { camelize } from "humps"
import ImmutablePropTypes from "react-immutable-proptypes"
import ToggleGroup from "highline/components/toggle_group"
import ToggleItem from "highline/components/toggle_item"
import Swatch from "highline/components/swatch"
import TextTag from "highline/components/text_tag"
import classNames from "classnames"
import { optionSizeMapper } from "highline/utils/variant_helper"
import { useViewportSize } from "highline/hooks/use_viewport_size"
import styles from "highline/styles/components/pdp/options.module.css"

// Options have 2 main variations and 5 sub-variations:
// 1. Options with swatches
//    For color swatches and digital giftcards
//    1.1 Core and/or limited
//    1.2 Icon Status (Has the icon status tooltip)
//    1.3 Sale and final sale (Group title is red)
//
// 2. Options with text
//    For any other product variations and physical giftcards
//    - 2.1 large text (text inside a pill, check the optionSizeMapper() to see which options are large)
//    - 2.2 small text (text inside a circle)
//
//  Additionally, color swatches can be forcibly hidden using showSwatches prop
//  and a info section can be added to the bottom of the options using sizeAndFitOptionTypes prop
//
// Color product variations gets and extra property called colorGroups.
// This property needs to be mapped to the colors "values" list
// because colorGroups only contains the color names.
// The colors "values" is converted to an object where the key is the color "name" to do: colorNameFromValues[colorNameFromColorGroups].name

const Options = ({
  isFinalSale,
  isGiftCard,
  isIconStatus,
  isSameProduct,
  onEducationCtaClick,
  onOptionChange,
  onOptionToggle,
  options,
  purchasedOptions,
  selectedOptions,
  showErrors,
  sizeAndFitOptionTypes,
  remainingOptions,
  showSwatches,
}) => {
  const { isSmartphone } = useViewportSize()
  const [hoveredColor, setHoveredColor] = useState(null)

  const handleSwatchMouseEnter = (name, color) => {
    if (name === "color") {
      setHoveredColor(color)
    }
  }

  const handleSwatchMouseLeave = () => {
    setHoveredColor(null)
  }

  let colorOptions = Map()
  if (options.size > 0) {
    colorOptions = options.find((option) => option.get("name") === "color")
  }

  const combinedResultsAndOptions = useMemo(() => {
    let result = []
    if (options.size > 0) {
      result = options.filter((option) => option.get("name") !== "color")
      if (showSwatches) {
        const colorOptionsToObject = colorOptions
          .toJS()
          .values.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})
        const colorGroups = colorOptions.get("colorsGroups")
        result = [
          ...colorGroups.map((item) =>
            fromJS({
              name: "color",
              colorId: item.get("id"),
              presentation: item.get("name"),
              shortPresentation: item.get("name"),
              values: item
                .get("colors")
                .map((colorName) => fromJS(colorOptionsToObject[colorName])),
            })
          ),
          ...result,
        ]
      }
    }
    return result
  }, [options])

  const finalAndSaleIds = [
    "onSale",
    "finalSale",
    "finalSale_highest",
    "finalSale_middle",
    "finalSale_lowest",
  ]

  return (
    <div className={classNames("component", "product-options-component", styles.component)}>
      {options.size > 0 && !isGiftCard && colorOptions.get("values").size > 0 && (
        <div style={{ width: "100%" }} className={styles.sortedSwatchSelectedColor}>
          <div className={styles.sortedSwatchSelectedColorTitle}>Color:&nbsp;</div>
          <div>{<span>{hoveredColor || selectedOptions.get("color")}</span>}</div>
        </div>
      )}

      {options.size > 0 &&
        combinedResultsAndOptions.map((optionType) => {
          const title = optionType.get("presentation")
          const name = optionType.get("name")
          const nameIncludesFit = name.includes("Fit")
          const isPantLength = name === "pantLength"
          const nameIsColor = name === "color"
          const isFinalAndSaleColor = finalAndSaleIds.includes(optionType.get("colorId"))
          let values = optionType.get("values")
            ? optionType.get("values").toList()
            : optionType.get("optionValues").toList()

          const selectedValue = selectedOptions.get(name)
          const purchasedValue = purchasedOptions && purchasedOptions.get(name)

          const needsSelection = Boolean(
            remainingOptions.find((option) => option.get("name") === name)
          )
          const showSizeAndFitCta = sizeAndFitOptionTypes.includes(camelize(name))
          const isTextLayout = isGiftCard || !nameIsColor
          return (
            <>
              {((showSwatches && nameIsColor) || name !== "color") && (
                <div
                  className={`${styles.input} ${!isTextLayout && styles.inputTypeSwatch}`}
                  key={name}
                  test-id={`toggleGroup-${optionType.get("colorId")}`}
                >
                  <ToggleGroup
                    canDeselect={nameIsColor ? false : true}
                    isFinalSale={isFinalSale}
                    isMobile={isSmartphone}
                    layout={isTextLayout ? "text" : "swatch"}
                    name={name}
                    onChange={onOptionChange}
                    onOptionToggle={onOptionToggle}
                    showError={showErrors && (isSameProduct ? !selectedValue : needsSelection)}
                    type="radio"
                    value={selectedValue}
                    title={
                      isSameProduct || (nameIsColor && !isGiftCard)
                        ? null
                        : nameIsColor && isGiftCard
                          ? "Color"
                          : title
                    }
                    titleSupplement={
                      <div
                        className={`
                      ${nameIsColor ? styles.sortedSwatchTitleSupplement : styles.titleSupplement} 
                      ${isFinalAndSaleColor && styles.sale}
                  `}
                      >
                        {nameIsColor && !isGiftCard && optionType.get("presentation")}
                      </div>
                    }
                    tooltipText={
                      isIconStatus && optionType.get("colorId") === "coreColors"
                        ? "Icon Status styles are our bread-and-butter, signature items that never go on sale. Invest in one for yourself, and if the fit's not right, returns are free and easy."
                        : null
                    }
                  >
                    {values.map((value) => {
                      return (
                        <ToggleItem
                          {...((nameIncludesFit || isPantLength) && {
                            ariaControlsID: "fit-educator-component",
                          })}
                          test-id={`toggleItem-${optionType.get("colorId")}`}
                          className={
                            purchasedValue == value.get("name") ? styles.exchangePurchase : ""
                          }
                          disabled={value.get("notProduced")}
                          key={value.get("name")}
                          value={value.get("name")}
                          size={optionSizeMapper(name)}
                          soldOut={!value.get("purchasable")}
                          handleSwatchMouseEnter={handleSwatchMouseEnter}
                          handleSwatchMouseLeave={handleSwatchMouseLeave}
                        >
                          {isGiftCard || !nameIsColor ? (
                            <TextTag>{value.get("presentation")}</TextTag>
                          ) : (
                            <Swatch imageUrl={value.get("url")} />
                          )}
                        </ToggleItem>
                      )
                    })}
                    {showSizeAndFitCta && (
                      <button
                        className={classNames(styles.ctaButton, styles.ctaAfter)}
                        aria-label={`Open ${optionType.get("shortPresentation")} Education Modal`}
                        onClick={() => {
                          onEducationCtaClick(camelize(name))
                        }}
                      >
                        Find your {optionType.get("shortPresentation").toLowerCase()}
                      </button>
                    )}
                  </ToggleGroup>
                </div>
              )}
            </>
          )
        })}
    </div>
  )
}

Options.propTypes = {
  isFinalSale: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isSameProduct: PropTypes.bool,
  onEducationCtaClick: PropTypes.func,
  onOptionChange: PropTypes.func,
  onOptionToggle: PropTypes.func,
  options: ImmutablePropTypes.list.isRequired,
  purchasedOptions: ImmutablePropTypes.map,
  remainingOptions: ImmutablePropTypes.list,
  selectedOptions: ImmutablePropTypes.map,
  showErrors: PropTypes.bool,
  sizeAndFitOptionTypes: ImmutablePropTypes.list,
  isIconStatus: PropTypes.bool,
  showSwatches: PropTypes.bool,
}

Options.defaultProps = {
  isFinalSale: false,
  isGiftCard: false,
  isSameProduct: false,
  onEducationCtaClick: () => {},
  onOptionChange: () => {},
  onOptionToggle: () => {},
  purcahseOptions: Map(),
  remainingOptions: List(),
  selectedOptions: Map(),
  showErrors: false,
  sizeAndFitOptionTypes: List(),
  showSwatches: true,
}

const MemoizedOptions = memo(Options)
MemoizedOptions.displayName = "Options"

export default MemoizedOptions
